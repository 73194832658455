import {
  ChartBarSquareIcon,
  ChatBubbleLeftIcon,
  UserGroupIcon,
  UsersIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { UserRole } from "apollo/cache/auth";
import {
  ComplaintPage,
  ComplaintsPage,
  CustomerPage,
  CustomersPage,
  DashboardPage,
  EnquiriesPage,
  ForgotPasswordPage,
  PartnerUsersPage,
  SigninPage,
} from "pages";
import { Outlet, Route } from "react-location";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserRole[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "IT Service Desk",
      section: "General",
    },
  },
  {
    path: "service-points",
    element: <CustomersPage />,
    sidebar: {
      label: "Service Points",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Customers",
      section: "Structure",
    },
    children: [
      {
        path: ":id",
        element: <CustomerPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Customer",
          section: "Structure",
        },
        withPermissions: ["Admin", "Super"],
      },
    ],
    withPermissions: ["Admin", "Super"],
  },
  {
    path: "tickets",
    element: <Outlet />,
    sidebar: {
      label: "Tickets",
      icon: TicketIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tickets",
      section: "Customer Relation",
    },
    children: [
      {
        element: <ComplaintsPage />,
        meta: {
          layout: "App",
          section: "Customer Relation",
        },
      },

      {
        path: ":complaint",
        element: <ComplaintPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Ticket",
          section: "Customer Relation",
        },
      },
    ],
    withPermissions: ["Admin", "Super"],
  },
  {
    path: "enquires",
    element: <EnquiriesPage />,
    sidebar: {
      label: "Enquiries",
      icon: ChatBubbleLeftIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Enquiries",
      section: "Customer Relation",
    },
    withPermissions: ["Admin", "Super"],
  },
  {
    path: "users",
    element: <PartnerUsersPage />,
    sidebar: {
      label: "Users",
      icon: UserGroupIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Users",
      section: "Configurations",
    },
    withPermissions: ["Super"],
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
];

export default routes;
