import { FC } from "react";
import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { PartnerUserForm } from "components/forms";
import { CREATE_PARTNER_USER } from "./apollo";
import * as Yup from 'yup'

interface CreatePartnerUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  partner: string;
}

const CreatePartnerUserContainer: FC<CreatePartnerUserContainerProps> = ({
  open,
  setOpen,
  refetch,
  partner,
}) => {
  const [createPartnerUser] = useMutation(CREATE_PARTNER_USER);

  const form = useFormik<any>({
    initialValues: {
      lastName: "",
      firstName: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
      role: "",
      gender: ""
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("Kindly enter the last name"),
      firstName: Yup.string().required("Kindly enter the other names"),
      ghanaCardNumber: Yup.string().required("Kindly enter the Ghana Card number"),
      phoneNumber: Yup.string().required("Kindly enter the Phone Number"),
      emailAddress: Yup.string().email("Kindly enter a valid email"),
      profileImageUrl: Yup.string(),
      role: Yup.string().oneOf(["Super", "Admin"]).required("Kindly choose the role"),
      gender: Yup.string().oneOf(["Male", "Female"]).required("Kindly choose the gender"),
    }),
    onSubmit: async (values) => {
      await createPartnerUser({
        variables: {
          ...values,
          partner,
        },
      }).then(({ data }) => {
        if (data.createPartnerUser._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Partner User Created Successfully",
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create Partner User" }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Partner User"
      description="Provide the details to add a new partner user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Adding Partner User..." : "Add Partner User"}
          </button>
        </>
      )}
    >
      <PartnerUserForm form={form} />
    </Modal>
  );
};

export default CreatePartnerUserContainer;
