import { FC } from "react";
import {
  EyeIcon,
  PencilSquareIcon,
  DocumentDuplicateIcon,
  CalculatorIcon,
  ArrowsPointingOutIcon,
  PaperAirplaneIcon,
  CalendarDaysIcon,
  UserPlusIcon,
  UserIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  ArrowPathRoundedSquareIcon,
  MagnifyingGlassPlusIcon,
  ArrowTopRightOnSquareIcon,
  Cog8ToothIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  StopCircleIcon,
  TicketIcon,
  TrashIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  LockClosedIcon,
  LockOpenIcon,
  CloudArrowUpIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { classNames, wrapClick } from "utils";
import "react-tooltip/dist/react-tooltip.css";

export const Actions = [
  "create",
  "update",
  "updateIdentity",
  "updateLocation",
  "updateTariff",
  "update",
  "view",
  "viewComplaint",
  "viewBulk",
  "configure",
  "calculate",
  "assign",
  "schedule",
  "send",
  "resolve",
  "approve",
  "disapprove",
  "reject",
  "reassign",
  "expand",
  "goto",
  "clone",
  "investigate",
  "suspend",
  "restore",
  "ticket",
  "submit",
  "terminate",
  "delete",
  "enable",
  "disable",
  "lock",
  "unlock",
  "complete",
  "cancel",
  "correct",
  "reset",
  "scrap",
  "refurbish",
  "reship",
  "view-order",
  "regularization",
  "simulator",
  "view-initiation",
  "upload",
  "escalate"
] as const;

export type Action = (typeof Actions)[number];
const ActionIcons: Partial<{ [key in Action]: typeof EyeIcon }> = {
  approve: HandThumbUpIcon,
  disapprove: HandThumbDownIcon,
  ticket: TicketIcon,
  assign: UserPlusIcon,
  calculate: CalculatorIcon,
  configure: Cog8ToothIcon,
  reassign: UserIcon,
  reject: ArrowPathRoundedSquareIcon,
  resolve: ClipboardDocumentListIcon,
  schedule: CalendarDaysIcon,
  send: PaperAirplaneIcon,
  update: PencilSquareIcon,
  updateIdentity: PencilSquareIcon,
  updateLocation: PencilSquareIcon,
  updateTariff: PencilSquareIcon,
  view: EyeIcon,
  viewBulk: EyeIcon,
  viewComplaint: EyeIcon,
  expand: ArrowsPointingOutIcon,
  goto: ArrowTopRightOnSquareIcon,
  clone: DocumentDuplicateIcon,
  investigate: MagnifyingGlassPlusIcon,
  suspend: PauseCircleIcon,
  restore: PlayCircleIcon,
  terminate: StopCircleIcon,
  submit: PaperAirplaneIcon,
  delete: TrashIcon,
  enable: TrashIcon,
  disable: TrashIcon,
  lock: LockOpenIcon,
  unlock: LockClosedIcon,
  complete: ClipboardDocumentCheckIcon,
  cancel: XCircleIcon,
  correct: PencilSquareIcon,
  reset: TrashIcon,
  scrap: TrashIcon,
  refurbish: TrashIcon,
  reship: TrashIcon,
  "view-order": EyeIcon,
  "view-initiation": EyeIcon,
  upload: CloudArrowUpIcon,
  escalate: ArrowTopRightOnSquareIcon,
};

interface ActionButtonProps {
  action: Action;
  onClick: (...val: any) => any;
  disabled?: boolean;
  tooltip?: string;
}

const ActionButton: FC<ActionButtonProps> = ({
  action,
  onClick,
  tooltip,
  disabled = false,
}) => {
  const Icon = ActionIcons[action];

  return (
    <button
      data-tooltip-delay-show={1000}
      data-tooltip-id="global-tooltip"
      data-tooltip-content={tooltip ?? action}
      type="button"
      onClick={wrapClick(onClick)}
      disabled={disabled}
      className={classNames(
        disabled
          ? "cursor-not-allowed text-gray-500 hover:bg-gray-300"
          : "text-gray-500 hover:bg-gray-300 hover:text-gray-900",
        "inline-flex items-center rounded-full border border-transparent p-1  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
      )}
    >
      { Icon &&  <Icon className="h-5 w-5" aria-hidden="true" /> }
    </button>
  );
};
export default ActionButton;
