import { useQuery } from "@apollo/client";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { default as lodash } from "lodash";
import numeral from "numeral";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames } from "utils";
import { useMemo } from "react";
import { GET_COMPLAINT_SUMMARY } from "pages/complaints/apollo";

const ticketStats = [
  {
    id: 1,
    name: "All Tickets",
    icon: UsersIcon,
    accessor: "totalTickets",
    emphasize: false
  },
  {
    id: 2,
    name: "Open Tickets",
    icon: EnvelopeOpenIcon,
    accessor: "totalOpenTickets",
    emphasize: false
  },
  {
    id: 3,
    name: "Tickets In Progress",
    icon: CursorArrowRaysIcon,
    accessor: "totalInProgressTickets",
    emphasize: false
  },
  {
    id: 4,
    name: "Closed Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "totalClosedTickets",
    emphasize: false
  },
  {
    id: 5,
    name: "Overdue Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "totalOverdueTickets",
    emphasize: true
  },
  {
    id: 6,
    name: "My Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "userTickets",
    emphasize: false
  },
  {
    id: 7,
    name: "My Open Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "userOpenTickets",
    emphasize: false
  },
  {
    id: 8,
    name: "My Tickets In Progress",
    icon: CursorArrowRaysIcon,
    accessor: "userInProgressTickets",
    emphasize: false
  },
  {
    id: 9,
    name: "My Closed Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "userClosedTickets",
    emphasize: false
  },
  {
    id: 10,
    name: "My Overdue Tickets",
    icon: CursorArrowRaysIcon,
    accessor: "userOverDueTickets",
    emphasize: true
  },
];


const TicketsOverviewContainer = () => {
  const searchParams = useSearch<LocationGenerics>();

  const filter = useMemo(
    () => ({
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
    }),
    [searchParams],
  );

  const { data  } = useQuery(
    GET_COMPLAINT_SUMMARY,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    },
  );

  return (
    <>
      <div className='col-span-12'>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-5">
          {ticketStats.map((item) => (
            <div
              key={item.id}
              className="flex items-center space-x-8 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6"
            >
              <div className="flex-1">
                <dt>
                  <p className=" truncate text-sm font-medium text-black">
                    {item.name}
                  </p>
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className={
                    classNames(
                      `flex items-baseline text-2xl font-semibold`,
                      item.emphasize ? "text-red-600/80" : "text-blue-700/80"
                    )}>
                    {numeral(
                      lodash.get(
                        data?.summary,
                        item.accessor,
                        0
                      )
                    ).format("0,0")}
                  </div>
                </dd>
              </div>
            </div>
          ))}
        </dl>
      </div>

      {/* <div className='col-span-4 h-[375px]'>
        <div className='flex flex-col gap-2 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6 h-full'>
          <p className=" truncate text-sm font-medium text-black">All Tickets By Status</p>
          <div className='flex flex-col gap-4'>
            {_.keys(_.omit(data?.summary, ["AllTickets", "__typename"])).map((accessor, idx) => (
              <StatBar
                summaryData={_.omit(data?.summary, ["AllTickets", "__typename"])}
                accessor={accessor}
                total={_.get(data?.summary, "AllTickets")}
                key={idx}
              />
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TicketsOverviewContainer;
