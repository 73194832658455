
import { gql, useQuery } from '@apollo/client';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import CustomersByGroupContainer from './customers-by-group';

const GET_COMPLAINTS_BY_GROUP_SUMMARY = gql`
  query GetComplaintsByGroupOverviewSummary(
      $region: ID
      $district: ID
      $fromDate: Date
      $toDate: Date
  ) {
    summary: getPartnerComplaintGroupSummary(
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
    ) {
      status {
        _id
        count
      }
      source{
        _id
        count
      }
      origin{
        _id
        count
      }
    }
  }
`;

const ComplaintsGraphOverviewContainer = () => {

  const searchParams = useSearch<LocationGenerics>()
  const { data } = useQuery(GET_COMPLAINTS_BY_GROUP_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined
    }
  });

  return (
    <>

      <div className='col-span-4'>
        <CustomersByGroupContainer
          groupBy='Status'
          data={data?.summary?.status}
        />
      </div>

      <div className='col-span-4'>
        <CustomersByGroupContainer
          groupBy='Source'
          data={data?.summary?.source}
        />
      </div>
      <div className='col-span-4'>
        <CustomersByGroupContainer
          groupBy='Origin'
          data={data?.summary?.origin}
        />
      </div>
    
    </>
  )
}

export default ComplaintsGraphOverviewContainer;
