import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components";
import config from "config";
import lodash from "lodash";
import moment from "moment";
import { FC } from "react";
import Map, { MapProvider, Marker } from "react-map-gl";
import { wrapImage } from "utils";

export const CustomerView: FC<any> = ({ servicePoint }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Customer Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about customer</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            {servicePoint?.customerType === "Individual" && (
              <div className="">
                <span className="text-xs font-light">Customer Information</span>
                <div className="grid grid-cols-4 gap-6 mt-2">
                  <div className="row-span-2 items-center justify-center flex flex-col">
                    <Avatar
                      alt={
                        servicePoint?.customerRepresentative.fullName || "N A"
                      }
                      src={
                        servicePoint?.customerRepresentative.profileImageUrl ||
                        ""
                      }
                      size="lg"
                    />
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.title || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Full Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.fullName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Nationality
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.nationality ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {servicePoint?.customerType === "Organization" && (
              <div className="">
                <span className="text-xs font-light">
                  Organization Information
                </span>
                <div className="grid grid-cols-4 gap-6 mt-2">
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerOrganization?.name || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {servicePoint?.customerType === "Individual" && (
              <div className="pt-6">
                <span className="text-xs font-light">Contact Information</span>
                <div className="grid grid-cols-4 gap-6 mt-2">
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Phone Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.phoneNumber ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Extra Phone Numbers
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.extraPhoneNumbers?.join(
                        ", "
                      ) || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Email Address
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.emailAddress ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {servicePoint?.customerType === "Organization" && (
              <div className="pt-6">
                <span className="text-xs font-light">
                  Representative Information
                </span>
                <div className="grid grid-cols-4 gap-6 mt-2">
                  <div className="col-start-1">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.title || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Full Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.fullName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Nationality
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.nationality ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Phone Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.phoneNumber ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Email Address
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.emailAddress ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Extra Phone Numbers
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.customerRepresentative?.extraPhoneNumbers?.join(
                        ", "
                      ) || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="pt-6">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Payee Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about payee</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="grid grid-cols-4 gap-6 mt-2">
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Full Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.payer?.fullName || "N/A"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Phone Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.payer?.primaryPhoneNumber || "N/A"}
                </div>
              </div>
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Email Address
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.payer?.primaryEmailAddress || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Extra Phone Numbers
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.payer?.phoneNumbers.join(", ") || "N/A"}
                </div>
              </div>
              {/* <div className='col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Extra Email Address
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {servicePoint?.payer?.emailAddresses.join(", ") || "N/A"}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(customer, null, 2)} */}
      {/* Service Information for Meter */}
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Service Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about service</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="">
              <span className="text-xs font-light">Service Information</span>
              <div className="grid grid-cols-4 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Account Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.accountCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Service Point Number (SPN)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.code || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Meter Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.meterCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Tariff Class
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.tariffClass?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Customer Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.customerType || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Contract Status
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.status || "N/A"}
                  </div>
                </div>
                { Boolean(servicePoint?.subStatus)
                  && !["CustomerRequest", "NonPayment", "NonTrace"].includes(servicePoint?.subStatus) // these sub-statuses are used for skirmishes
                  && (
                    <>
                      <div>
                      <span className="block text-sm font-light text-gray-700">
                        Sub Status
                      </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {lodash.startCase(servicePoint?.subStatus)}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">Meter Information</span>
              <div className="grid grid-cols-4 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Brand
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.meter?.brand?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Model
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.meter?.model?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Model Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.meter?.modelType || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Meter Phase
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.meter?.modelMeta?.phase || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Customer Identity Section */}
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Customer Identity Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about identity of customer
            </p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <>
              {servicePoint?.customerRepresentative?.hasGhanaCard ? (
                <div className="">
                  <span className="text-xs font-light">
                    Representative Identity Information
                  </span>
                  <div className="grid grid-cols-8 gap-6 mt-2">
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Ghana Card Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {servicePoint?.customerRepresentative
                          ?.ghanaCardNumber || "N/A"}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Ghana Card Issue Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {servicePoint?.customerRepresentative?.ghanaCardIssueDate
                          ? moment(
                            servicePoint?.customerRepresentative
                              ?.ghanaCardIssueDate
                          ).format(dateFormat)
                        : "N/A"}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Ghana Card Expiry Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {servicePoint?.customerRepresentative?.ghanaCardExpiryDate
                          ? moment(
                            servicePoint?.customerRepresentative
                              ?.ghanaCardExpiryDate
                          ).format(dateFormat)
                        : "N/A"}
                      </div>
                    </div>
                    <div className="col-span-4">
                      <span className="block text-sm font-light text-gray-700">
                        Ghana Card Front Image
                      </span>
                      <div className="mt-2">
                        {servicePoint?.customerRepresentative?.ghanaCardFrontImageUrl
                          ? wrapImage(
                            <img
                              src={
                                servicePoint?.customerRepresentative
                                  ?.ghanaCardFrontImageUrl
                              }
                              alt={"front"}
                              className="w-full h-64 text-xs"
                            />
                          ) : (
                          <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                            Image not provided
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-4">
                      <span className="block text-sm font-light text-gray-700">
                        Ghana Card Back Image
                      </span>
                      <div className="mt-2">
                        {wrapImage(
                          <img
                            src={
                              servicePoint?.customerRepresentative
                                ?.ghanaCardBackImageUrl
                            }
                            alt={"back"}
                            className="w-full h-64 text-xs"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="">
                  <span className="text-xs font-light">
                    Representative Identity Information
                  </span>
                  <div className="grid grid-cols-8 gap-6 mt-2">
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        ID Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {servicePoint?.customerRepresentative
                          ?.identityCardNumber || "N/A"}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        ID Type
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {lodash.startCase(
                          servicePoint?.customerRepresentative?.identityCardType
                        ) || "N/A"}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        ID Issue Date
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {servicePoint?.customerRepresentative?.identityCardIssueDate
                          ? moment(
                            servicePoint?.customerRepresentative
                              ?.identityCardIssueDate
                          ).format(dateFormat)
                        : "N/A"}
                      </div>
                    </div>
                    {servicePoint?.customerRepresentative?.identityCardType !==
                      "VotersIdentificationCard" && (
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Expiry Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          { servicePoint?.customerRepresentative?.identityCardExpiryDate
                            ? moment(
                              servicePoint?.customerRepresentative
                                ?.identityCardExpiryDate
                            ).format(dateFormat)
                          : "N/A"}
                        </div>
                      </div>
                    )}
                    <div className="col-span-4">
                      <span className="block text-sm font-light text-gray-700">
                        ID Front Image
                      </span>
                      <div className="mt-2">
                        {servicePoint?.servicePoint?.representative?.identityCardFrontImageUrl
                          ? wrapImage(
                            <img
                              src={
                                servicePoint?.customerRepresentative
                                  ?.identityCardFrontImageUrl
                              }
                              alt={"front"}
                              className="w-full h-64 text-xs"
                            />
                          ) : (
                            <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                              Image not provided
                            </div>
                          )
                        }
                      </div>
                    </div>
                    {servicePoint?.customerRepresentative?.identityCardType ===
                      "DriversLicense" && (
                      <div className="col-span-4">
                        <span className="block text-sm font-light text-gray-700">
                          ID Back Image
                        </span>
                        <div className="mt-2">
                          { servicePoint?.servicePoint?.representative?.identityCardBackImageUrl
                            ? wrapImage(
                              <img
                                src={
                                  servicePoint?.servicePoint?.representative
                                    ?.identityCardBackImageUrl
                                }
                                alt={"back"}
                                className="w-full h-64 text-xs"
                              />
                            ) : (
                              <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                                Image not provided
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
            {servicePoint?.servicePoint?.customerType === "Organization" && (
              <div className="pt-6">
                <span className="text-xs font-light">
                  Organization Identity Information
                </span>
                <div className="grid grid-cols-6 gap-6 mt-2">
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Tax Identification Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.servicePoint?.organization
                        ?.taxIdentificationNumber || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Organization Registration Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.servicePoint?.organization
                        ?.organizationRegistrationNumber || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Organization Registration Date
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {servicePoint?.servicePoint?.organization?.organizationRegistrationDate
                        ? moment(
                          servicePoint?.servicePoint?.organization
                            ?.organizationRegistrationDate
                        ).format(dateFormat)
                      : "N/A"}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Certificate of Incorporation
                    </span>
                    <div className="mt-2">
                      {servicePoint?.servicePoint?.organization?.certificateOfIncorporationDocumentUrl
                        ? wrapImage(
                          <img
                            src={
                              servicePoint?.servicePoint?.organization
                                ?.certificateOfIncorporationDocumentUrl
                            }
                            alt={"Certificate of Incorporation"}
                            className="w-full h-64 text-xs"
                          />
                        ) : (
                          <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                            Document not provided
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Business Registration Document
                    </span>
                    <div className="mt-2">
                      {servicePoint?.servicePoint?.organization?.organizationRegistrationDocumentUrl
                        ? wrapImage(
                          <img
                            src={
                              servicePoint?.servicePoint?.organization
                                ?.organizationRegistrationDocumentUrl
                            }
                            alt={"Business Registration Document"}
                            className="w-full h-64 text-xs"
                          />
                        ) : (
                          <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                            Registration document not provided
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Property Information */}

      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Property Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="">
              <span className="text-xs font-light">Owner Information</span>
              <div className="grid grid-cols-4 gap-6 mt-2">
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Full name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.owner?.fullName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Phone Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.owner?.phoneNumber || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">Property Information</span>
              <div className="grid grid-cols-2 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Property Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.propertyCode || "N/A"}
                  </div>
                </div>

                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Ghana Post Address
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.ghanaPostAddress || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Address
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.streetName || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Closest Landmark
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.landmark || "N/A"}
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <span className="text-xs font-light">Premise Information</span>
              <div className="grid grid-cols-4 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Premise Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.premiseType?.name || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Premise Category
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.premiseCategory?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Activity
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.activity?.name || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Sub Activity
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.subActivity?.name || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6 grid grid-cols-2 gap-6">
              <div>
                <span className="text-xs font-light">
                  Site Plan Information
                </span>
                {servicePoint?.property?.sitePlanDocumentUrl ? (
                  <div className="mt-2">
                    {wrapImage(
                      <img
                        src={servicePoint?.property?.sitePlanDocumentUrl}
                        alt={servicePoint?.property?.structureNumber}
                        className="w-full h-64 text-xs"
                      />
                    )}
                  </div>
                ) : (
                  <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                    Site plan not provided
                  </div>
                )}
              </div>
              <div>
                <span className="text-xs font-light">Map Information</span>
                <div className="mt-2 w-full h-64 rounded-md overflow-hidden">
                  <MapProvider>
                    <Map
                      id="service-location"
                      initialViewState={{
                        longitude:
                          servicePoint?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361,
                        latitude:
                          servicePoint?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032,
                        zoom: 15,
                      }}
                      mapStyle="mapbox://styles/mapbox/streets-v12"
                      mapboxAccessToken={config.mapbox.token}
                    >
                      <Marker
                        longitude={
                          servicePoint?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361
                        }
                        latitude={
                          servicePoint?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032
                        }
                      >
                        <img
                          className="w-6 h-6"
                          src={"/logo.png"}
                          alt={"pin"}
                        />
                      </Marker>
                    </Map>
                  </MapProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Geospatial Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Geospatioal Details about property
            </p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="">
              <span className="text-xs font-light">Geospatial Information</span>
              <div className="grid grid-cols-4 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Region
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.regionName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    District
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.districtName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Block
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.blockName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Round
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.roundName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Plot
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.plotCode || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Structure Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.structureNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Northing (Y)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.cartesianLocation?.northing || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Easting (X)
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.cartesianLocation?.northing || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Zone
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.property?.zone || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Geo Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {servicePoint?.geoCode || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Service Information */}
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Service Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about service being requested for
            </p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="grid grid-cols-3 gap-6 mt-2">
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certification Issuer Name
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certification Phone Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certification Issuer ID
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Energy Certificate Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {servicePoint?.energyCertificateNumber || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Energy Certificate Document
                </span>
                <div className="mt-1 block sm:text-sm">
                  {servicePoint?.energyCertificateDocumentUrl ? (
                    <>
                      {wrapImage(
                        <img
                          src={servicePoint?.energyCertificateDocumentUrl}
                          alt={servicePoint?.energyCertificateNumber}
                          className="w-full  h-64 object-cover object-top text-xs"
                        />
                      )}
                    </>
                  ) : (
                    <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                      Energy certificate document not provided
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Extra Attribute Information */}
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Extra Attribute Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about extra attributes
            </p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="grid grid-cols-3 gap-6 mt-2">
              {servicePoint?.extraAttributes?.map((attribute: any) => (
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    {attribute.name}
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {lodash.isBoolean(attribute?.value)
                      ? (attribute.value
                        ? "Yes"
                        : "No")
                      : (attribute.value || "N/A")}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerView;
