
import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';
import { FC } from 'react';

interface PieData {
  id: string;
  label: string;
  value: number;
}

const CustomersByGroupContainer: FC<{ 
  groupBy: string
  data?: { _id: string, count: number }[]
 }> = ({ groupBy, data }) => {

  const pieData: PieData[] = (data ?? []).map((group, idx) => ({
    id: _.startCase(group._id),
    label: _.startCase(group._id),
    value: group.count
  }))

  return (
    <>
      <div className='flex flex-col gap-4 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6 h-[375px]'>
        <p className=" truncate text-sm font-medium text-black">All Tickets By {_.startCase(groupBy)}</p>
        <div className='grid place-items-center h-full'>
          {
            !!data?.length ? (
              <>
                <div className='h-[275px] w-[255px] '>
                  <ResponsivePie
                    data={pieData}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    innerRadius={0.6}
                    cornerRadius={0}
                    activeOuterRadiusOffset={0}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [
                        [
                          'darker',
                          0.2
                        ]
                      ]
                    }}
                    colors={{ scheme: 'category10'}} 
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={"white"}
                    layers={[ 'arcs', 'arcLabels', 'legends']}
                    
                  />

                </div>
              </>
            ) : (
              <p className='text-sm text-gray-400'>No Data</p>
            )
          }
        </div>
      </div>
    </>
  )
}

export default CustomersByGroupContainer;
