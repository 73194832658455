import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, PartnerUserForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_PARTNER_USER, UPDATE_PARTNER_USER } from "./apollo";
import * as Yup from 'yup'

interface UpdatePartnerUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  partner: string;
}

const UpdatePartnerUserContainer: FC<UpdatePartnerUserContainerProps> = ({
  open,
  setOpen,
  refetch,
  partner
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: loadingUser } = useQuery(GET_PARTNER_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updatePartnerUser, { loading }] = useMutation(UPDATE_PARTNER_USER);

  const form = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      gender: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
      role: ""
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("Kindly enter the last name"),
      firstName: Yup.string().required("Kindly enter the other names"),
      ghanaCardNumber: Yup.string().required("Kindly enter the Ghana Card number"),
      phoneNumber: Yup.string().required("Kindly enter the Phone Number"),
      emailAddress: Yup.string().email("Kindly enter a valid email"),
      profileImageUrl: Yup.string(),
      role: Yup.string().oneOf(["Super", "Admin"]).required("Kindly choose the role"),
      gender: Yup.string().oneOf(["Male", "Female"]).required("Kindly choose the gender")
    }),
    onSubmit: async (values) => {
      await updatePartnerUser({
        variables: {
          id: searchParams.id,
          ...values,
          partner,
        },
      }).then(({ data }) => {
        if (data.updatePartnerUser._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Partner User Edited Successfully",
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not edit Partner User" }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      lastName: data?.user?.lastName || "",
      firstName: data?.user?.firstName || "",
      gender: data?.user?.gender || "",
      ghanaCardNumber: data?.user?.ghanaCardNumber || "",
      phoneNumber: data?.user?.phoneNumber || "",
      emailAddress: data?.user?.emailAddress || "",
      profileImageUrl: data?.user?.profileImageUrl || "",
      role: data?.user?.role
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loadingUser}
      title="Edit User Information"
      description="Provide the details to update user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing User..." : "Edit User"}
          </button>
        </>
      )}
    >
      {data?.user?._id && <PartnerUserForm form={form} />}
    </Modal>
  );
};

export default UpdatePartnerUserContainer;
