import { gql } from "@apollo/client";

export const GET_PARTNER_USERS = gql`
  query GetPartnerUsers(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $partner: ID
    $allowDisabledUsers: Boolean
  ) {
    rows: getPartnerUsers(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      partner: $partner
      allowDisabledUsers: $allowDisabledUsers
    ) {
      _id
      code
      lastName
      firstName
      gender
      ghanaCardNumber
      phoneNumber
      emailAddress
      profileImageUrl
      role
      status
      partner {
        _id
      }
      createdAt
      updatedAt
    }
    count: getPartnerUsersCount(
      search: $search
      searchFields: $searchFields
      partner: $partner
      allowDisabledUsers: $allowDisabledUsers
    )
  }
`;

export const GET_PARTNER_USER = gql`
  query GetPartnerUser($id: ID!) {
    user: getPartnerUser(id: $id) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      partner {
        _id
      }
      role
      status
      gender
      meta {
        isPasswordSet
        isBlocked
        isDeleted
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const RESET_PARTNER_USER_FINGERPRINT = gql`
  mutation ResetFingerprint($id: ID!) {
    resetPartnerUserFingerprint(id: $id) {
      _id
    }
  }
`;

export const UPDATE_PARTNER_USER = gql`
  mutation UpdatePartnerUser(
    $id: ID!
    $lastName: String
    $firstName: String
    $gender: Gender
    $ghanaCardNumber: String
    $phoneNumber: String
    $emailAddress: String
    $profileImageUrl: String
    $role: PartnerUserRole
  ) {
    updatePartnerUser(
      id: $id
      lastName: $lastName
      firstName: $firstName
      gender: $gender
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      profileImageUrl: $profileImageUrl
      role: $role
    ) {
      _id
    }
  }
`;

export const CREATE_PARTNER_USER = gql`
  mutation CreatePartnerUser(
    $lastName: String!
    $firstName: String!
    $ghanaCardNumber: String
    $gender: Gender
    $phoneNumber: String!
    $emailAddress: String
    $profileImageUrl: String
    $partner: ID
    $role: PartnerUserRole
  ) {
    createPartnerUser(
      lastName: $lastName
      firstName: $firstName
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      gender: $gender
      profileImageUrl: $profileImageUrl
      partner: $partner
      role: $role
    ) {
      _id
    }
  }
`;


export const ENABLE_PARTNER_USER = gql`
  mutation EnableUser(
    $id: ID!
  ) {
    enablePartnerUser(
      id: $id
    ) {
      _id
    }
  }
`;

export const DISABLE_PARTNER_USER = gql`
  mutation DisablePartnerUser(
    $id: ID!
  ) {
    disablePartnerUser(
      id: $id
    ) {
      _id
    }
  }
`;
