import { useQuery } from "@apollo/client";
import { Header, Shimmers, StatusBadge, TableComponent,  } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  withPermissions,
  useTableData,
} from "utils";
import config from "config";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreatePartnerUserContainer from "./create";
import ViewUserContainer from "./view";
import ActionButton, { Action } from "components/buttons/action-button";
import UpdatePartnerUserContainer from "./update";
import moment from "moment";
import { currentUserVar } from "apollo/cache/auth";
import { GET_PARTNER_USERS } from "./apollo";


const PartnerUsersPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [, setSearch] = useUrlState("search");

  const currentUser = currentUserVar()

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: searchParams.search ? `${searchParams.search}` : "",
      searchFields: ["code", "lastName", "firstName", "ghanaCardNumber"],
      sort: searchParams.sort || "",
      partner: currentUser.partner._id
    }),
    [searchParams, currentUser.partner?._id]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_PARTNER_USERS, {
    variables: filter,
    // pollInterval,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.minSearch ? { minSearch: 5 } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header
        renderActions={() => (
          <>
            {withPermissions(["Super"])(
              <button
                type='button'
                onClick={wrapClick(() => setModal("create"))}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Add New User
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"users"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={false}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Name
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Phone
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Email Address
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Role
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Created At
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Status
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {[
                      (item?.lastName || "")?.trim(),
                      (item?.firstName || "")?.trim(),
                    ]
                      .join(" ")
                      .trim() || "N A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.emailAddress || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <span
                    className={classNames(
                      item?.role === "Super"
                        ? "bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50"
                        : "",
                      item?.role === "Admin"
                        ? "bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50"
                        : "",
                      !item?.role
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{item?.role || "Unknown"}</span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format("DD/MM/YYYY HH:mm A") ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <StatusBadge
                    status={item?.status}
                    options={{
                      Active: "bg-green-100 text-green-800 ",
                      Suspended: "bg-amber-100 text-amber-800 ",
                      Deleted: "bg-red-100 text-red-800 ",
                    }}
                  />
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["Super"])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(["Super"])(
        <CreatePartnerUserContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
          partner={currentUser.partner._id}
        />
      )}

      {!!searchParams.id?.length && (
        <>
          {withPermissions(["Super"])(
            <UpdatePartnerUserContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
              partner={currentUser.partner._id}
            />
          )}
          <ViewUserContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default PartnerUsersPage;
