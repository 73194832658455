
import { OfficeCalendarHeader } from 'components';
import { FC } from 'react';
import TicketsOverviewContainer from './containers/tickets-overview';
import ComplaintsGraphOverviewContainer from './containers/complaints-graph-overview';


const DashboardPage: FC = () => {


  return (
    
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeCalendarHeader
        renderActions={() => (
          <>
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
              <TicketsOverviewContainer />
              <ComplaintsGraphOverviewContainer />
          </div>
        </div>
      </div>
    </main>
  )
}

export default DashboardPage;
