import { useMutation, useQuery } from "@apollo/client";
import { LocationGenerics } from "router/location";
import { dispatchModal, withPermissions, wrapClick } from "utils";
import { Modal, PartnerUserView } from "components";
import { FC } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import {
  GET_PARTNER_USER,
  ENABLE_PARTNER_USER,
  DISABLE_PARTNER_USER,
} from "./apollo";

interface ViewPartnerUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const ViewPartnerUserContainer: FC<ViewPartnerUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading, refetch: refetchPartnerUser } = useQuery(GET_PARTNER_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const [enablePartnerUser, { loading: enablingPartnerUser }] = useMutation(
    ENABLE_PARTNER_USER,
    {
      onCompleted: () => {
        refetchPartnerUser()
        refetch?.();
        toast(
          JSON.stringify({
            type: "success",
            title: "User enabled successfully",
          })
        );
      },
      onError: (error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: "Could not enable user",
          })
        );
      },
    }
  );
  const [disablePartnerUser, { loading: disablingPartnerUser }] = useMutation(
    DISABLE_PARTNER_USER,
    {
      onCompleted: () => {
        refetchPartnerUser()
        refetch?.();
        toast(
          JSON.stringify({
            type: "success",
            title: "User disabled successfully",
          })
        );
      },
      onError: (error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: "Could not disable user",
          })
        );
      },
    }
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Partner User Information"
      description="Details of user are shown below"
      renderActions={() => (
        <>
          {withPermissions([
            "Super"
          ])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(dispatchModal(data?.user?._id, "update"))}
            >
              Edit
            </button>,
          )}
          {withPermissions(["Super"])(
            <>
              { 
                data?.user?.status === 'Active' ? 
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>
                      disablePartnerUser({ variables: { id: searchParams.id } })
                    }>
                  {disablingPartnerUser ? " Disabling ..." : "Disable"}
                  </button>
                  : 
                  null
              }
            </>
          )}
          {withPermissions(["Super"])(
            <>
              { 
                data?.user?.status === 'Suspended' ? 
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>
                      enablePartnerUser({ variables: { id: searchParams.id } })
                    }>
                  {enablingPartnerUser ? " Enabling ..." : " Enable"}
                  </button>
                  : 
                  null
              }
            </>
          )}
        </>
      )}
    >
      {data?.user?._id && <PartnerUserView user={data?.user} />}
    </Modal>
  );
};

export default ViewPartnerUserContainer;
